import React, { lazy, useEffect, useState, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Fallback from "../Fallback";
import Layout from "../layout/layout";
import AppRoute from "./route";


// ---------- Common ---------- //
const Logout = lazy(() => import("../pages/logout"));
const BRAP = lazy(() => import("../pages/brapReport"));

// ---------- Investor ---------- //
const Homepage = lazy(() => import("../pages/homepage/homepage"));
const Register = lazy(() => import("../pages/register/register"));
const AboutUs = lazy(() => import("../pages/aboutUs/aboutUs"));
const ServicesAndSchemes = lazy(() => import("../pages/servicesAndSchemes/servicesAndSchemes"));
const ImportantDownloads = lazy(() => import("../pages/investorZone/importantDownloads"));
const Proceeding = lazy(() => import("../pages/investorZone/proceeding"));

const Guidelines = lazy(() => import("../pages/guidelines"));
const QuickLinks = lazy(() => import("../pages/quickLinks"));
const PoliciesAndActs = lazy(() => import("../pages/policiesActs"));
const InvestorRedirect = lazy(() => import("../pages/investorRedirect"));
const Sector = lazy(() => import("../pages/sectors/sectors"));
const Faq = lazy(() => import("../pages/faq/faq"));
const ContactUs = lazy(() => import("../pages/contactUs/contactUs"));
// const NoticeBoard = lazy(() => import("../pages/noticeBoard/noticeBoard"));
const InvestorHome = lazy(() => import("../pages/investorHome/investorHome"));
const QuarterReport = lazy(() => import("../containers/investorDashboard/quarterReport"));
const PreIncentiveForm = lazy(() => import("../containers/investorDashboard/preIncentiveForm"));


const ParichaySSO = lazy(() => import("../pages/parichaySSO"));
const HyperlinkPolicy = lazy(() => import("../pages/hyperlinkPolicy"));
const AccessibilityStatement = lazy(() => import("../pages/accessibilityStatement"));
const Copyright = lazy(() => import("../pages/copyright"));
const Help = lazy(() => import("../pages/help"));
const LicencePermitWizard = lazy(() => import("../pages/licencePermitWizard"));
const District = lazy(() => import("../pages/district"));
const NoticeBoard = lazy(() => import("../pages/noticeBoardHome"));
const Privacy = lazy(() => import("../pages/privacy"));
const TermsOfUse = lazy(() => import("../pages/termsOfUse"));
const IncentiveCalculator = lazy(() => import("../pages/incentiveCalculator"));
const GrievanceReport = lazy(() => import("../pages/reports/grievance"));
const IncentiveGranted = lazy(() => import("../pages/reports/incentiveGranted"));
const QueriesHandled = lazy(() => import("../pages/reports/queriesHandled"));
const OtherLevies = lazy(() => import("../pages/otherLevies"));
const Gallery = lazy(() => import("../pages/gallery"));




// ---------- Department ---------- //
const DepartmentDashboard = lazy(() => import("../pages/department/dashboard"));
const DepartmentLogin = lazy(() => import("../pages/department//login"));
const DepartmentMisReport = lazy(() => import("../pages/department/misReport"));
const DepartmentApplicationDetails = lazy(() => import("../pages/department/applicationDetails"));
const DepartmentAdminDashboard = lazy(() => import("../pages/department/adminDashboard"));
const DepartmentAdminOverviewDashboard = lazy(() => import("../pages/department/adminOverviewDashboard"));
const DepartmentAddUser = lazy(() => import("../pages/department/addUser"));
const DepartmentUnblockUser = lazy(() => import("../pages/department/unblockUser"));
const DepartmentSIPBMeeting = lazy(() => import("../pages/department/sipbMeeting"));
const DepartmentDocumentManagement = lazy(() => import("../pages/department/documentManagement"));
// const DepartmentRoleAccess = lazy(() => import("../pages/department/roleAccess"));
const DepartmentSingleApplication = lazy(() => import("../pages/department/singleApplication"));
const DepartmentReport = lazy(() => import("../pages/department/report"));
const Feedback = lazy(() => import("../pages/feedback"));
const UploadProceeding = lazy(() => import("../pages/department/uploadProceeding"));
const Ownership = lazy(() => import("../pages/department/ownership"));
const GuidelinesSection = lazy(() => import("../pages/department/cms/guidelines"));
const Policies = lazy(() => import("../pages/department/cms/policies"));
const Banner = lazy(() => import("../pages/department/cms/banner"));
const Contacts = lazy(() => import("../pages/department/cms/contacts"));
const AllUser = lazy(() => import("../pages/department/listUser"));
const Roles = lazy(() => import("../pages/department/addRoles"));
const AllRoles = lazy(() => import("../pages/department/allRoles"));
const AllInvestor = lazy(() => import("../pages/department/allInvestor"));
const ChangePassword = lazy(() => import("../pages/department/changePassword"));



// http://localhost:3000/investor/investor-home/dashboard/view/id/quarter-report/id/1

const departmentPath = "/department";
const inversePath = "/investor";

const investorAuthRoues = [
    { path: "/logout/investor", component: Logout },
    { path: inversePath + "/report", exact: true, component: QuarterReport },
    { path: inversePath + "/investor-home/pre-incentive", exact: true, component: PreIncentiveForm },
    { path: inversePath + "/investor-home/:mainSection", exact: true, component: InvestorHome },//Home page
    { path: inversePath + "/investor-home/:mainSection/:eid", exact: true, component: InvestorHome },
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid", exact: true, component: InvestorHome },//FC
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid/:section", exact: true, component: InvestorHome },//enterprize
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid/:section/:id", exact: true, component: InvestorHome },//Quarter
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid/:caf/:unitId/:section", exact: true, component: InvestorHome },//CAF
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid/:section/:fcId/:wizardSection/:incentive", exact: true, component: InvestorHome },//Incentive
    { path: inversePath + "/investor-home/:mainSection/:operation/:eid/:section/:unitLicenceId/:wizardSection/:cafSection/:sectionOperation", exact: true, component: InvestorHome },//CAF2-CAF3
]

const departmentAuthRoutes = [
    { path: "/logout/department", exact: true, component: Logout },
    { path: departmentPath + "/dashboard", exact: true, component: DepartmentDashboard },
    { path: departmentPath + "/single_application/:id/:type", exact: true, component: DepartmentSingleApplication },
    { path: departmentPath + "/single_application/:id/:type/:incentiveType", exact: true, component: DepartmentSingleApplication },
    { path: departmentPath + "/view_single_application/:id/:type", exact: true, component: DepartmentSingleApplication },
    { path: departmentPath + "/mis_report", exact: true, component: DepartmentMisReport },

    //------//user management
    { path: departmentPath + "/add_user", exact: true, component: DepartmentAddUser },
    { path: departmentPath + "/unblock_user", exact: true, component: DepartmentUnblockUser },
    //------//SIPM Meeting
    { path: departmentPath + "/sipb_meeting", exact: true, component: DepartmentSIPBMeeting },
    //------//Application Management
    { path: departmentPath + "/application_detail", exact: true, component: DepartmentApplicationDetails },
    { path: departmentPath + "/admin_dashboard", exact: true, component: DepartmentAdminDashboard },
    { path: departmentPath + "/admin_overview_dashboard/:section/:serviceType", exact: true, component: DepartmentAdminOverviewDashboard },
    { path: departmentPath + "/role_access", exact: true, component: AllRoles },
    { path: departmentPath + "/document_management", exact: true, component: DepartmentDocumentManagement },
    { path: departmentPath + "/report/:section", exact: true, component: DepartmentReport },

    //------//
    { path: departmentPath + "/upload_proceeding", exact: true, component: UploadProceeding },
    { path: departmentPath + "/ownership", exact: true, component: Ownership },
    { path: departmentPath + "/users", exact: true, component: AllUser },

    //CMS
    { path: departmentPath + "/guidelines", exact: true, component: GuidelinesSection },
    { path: departmentPath + "/policies", exact: true, component: Policies },
    { path: departmentPath + "/banner", exact: true, component: Banner },
    { path: departmentPath + "/contacts", exact: true, component: Contacts },


    { path: departmentPath + "/dms", exact: true, component: AllInvestor },
    { path: departmentPath + "/change_password", exact: true, component: ChangePassword },
    // { path: departmentPath + "/roles", exact: true, component: Roles },
    // { path: departmentPath + "/roles", exact: true, component: AllRoles },


]

const openRoutes = [
    { path: "/sipb", exact: true, component: Homepage },
    { path: inversePath + "/feedback", exact: true, component: Feedback },
    { path: inversePath + "/homepage", exact: true, component: Homepage },
    { path: inversePath + "/services-and-schemes/:section", exact: true, component: ServicesAndSchemes },
    { path: inversePath + "/important-downloads", exact: true, component: ImportantDownloads },
    { path: inversePath + "/proceeding", exact: true, component: Proceeding },
    { path: inversePath + "/guidelines", exact: true, component: Guidelines },
    { path: inversePath + "/policies-acts", exact: true, component: PoliciesAndActs },
    { path: inversePath + "/investor-redirect", exact: false, component: InvestorRedirect },
    { path: inversePath + "/sectors", exact: true, component: Sector },
    { path: inversePath + "/faq", exact: true, component: Faq },
    { path: inversePath + "/contact-us/:section", exact: true, component: ContactUs },
    { path: inversePath + "/about-us/:section", exact: true, component: AboutUs },
    // { path: inversePath + "/notice-board", exact: true, component: NoticeBoard },
    { path: inversePath + "/quick-links", exact: true, component: QuickLinks },
    { path: inversePath + "/hyperlink-policy", exact: true, component: HyperlinkPolicy },
    { path: inversePath + "/accessibility-statement", exact: true, component: AccessibilityStatement },
    { path: inversePath + "/copyright", exact: true, component: Copyright },
    { path: inversePath + "/help", exact: true, component: Help },
    { path: inversePath + "/licence-permit-wizard", exact: true, component: LicencePermitWizard },
    { path: inversePath + "/district", exact: true, component: District },
    { path: inversePath + "/notice-board", exact: true, component: NoticeBoard },
    { path: inversePath + "/privacy-policy", exact: true, component: Privacy },
    { path: inversePath + "/terms-of-use", exact: true, component: TermsOfUse },
    { path: inversePath + "/investor-home", exact: true, component: InvestorHome },
    { path: inversePath + "/eodb", exact: true, component: BRAP },
    { path: inversePath + "/parichay-sso", exact: true, component: ParichaySSO },
    { path: inversePath + "/calculator", exact: true, component: IncentiveCalculator },
    { path: inversePath + "/grievance-report", exact: true, component: GrievanceReport },
    { path: inversePath + "/incentive-granted", exact: true, component: IncentiveGranted },
    { path: inversePath + "/queries-handled", exact: true, component: QueriesHandled },
    { path: inversePath + "/other-levies", exact: true, component: OtherLevies },
    { path: inversePath + "/biharhaitaiyar", exact: true, component: Gallery },
];

const investorLogin = [
    { path: inversePath + "/register", exact: true, component: Register },
]

const departmentLogin = [
    { path: "department-login", exact: true, component: DepartmentLogin },
]

const PagesRoute = (props) => {
    const location = useLocation();
    const [pathName, setPathName] = useState(false);

    const [allRoues, setAllRoues] = useState([])

    useEffect(() => {
        setAllRoues([...openRoutes])
        // console.log("asdasdsad")
    }, [])

    useEffect(() => {
        if (location.pathname && location.pathname.includes("department")) {
            // console.log(location.pathname);
            setPathName("department");
        }
        if (location.pathname && location.pathname.includes("investor")) {
            // console.log(location.pathname);
            setPathName("investor");
        }
        // console.log(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        // console.log(props.department)
    }, [props.department])

    useEffect(() => {
        let _allRoues = [...openRoutes];
        // console.log(props.investor.userLoginData)
        if (props.investor.userLoginData && props.investor.userLoginData.token) {
            _allRoues = [..._allRoues, ...investorAuthRoues, ...departmentLogin]
        }
        else {
            _allRoues = [..._allRoues, ...departmentLogin]
        }

        if (props.department.authDetails && props.department.authDetails.token) {
            //
            _allRoues = [..._allRoues, ...departmentAuthRoutes, ...investorLogin]
        }
        else {
            _allRoues = [..._allRoues, ...investorLogin]
        }

        // console.log(_allRoues)

        setAllRoues(_allRoues)
    }, [props.investor, props.department])

    const generateRoute = (allRoutes) => {
        let _data = [];
        allRoutes.map(({ path, component: Component, ...route }, i) => {
            _data.push(<Route
                path={path}
                key={i}
                exact={true}
                element={
                    <Suspense fallback={<></>}>
                        <Component />
                    </Suspense>
                }
            />)
        })
        // else
        //     _data.push(
        //         <Route path={"*"} element={<Navigate replace to="investor/register" />} isAuthProtected={false} />
        //     )
        return <Routes>
            {_data}
        </Routes>

    }

    // console.log(pathName);
    return (
        <div>
            {pathName !== " " ? (
                <>
                    <Routes>
                        <Route path="/" element={<Navigate replace to="investor/homepage" />} />
                        <Route path="/biharhaitaiyar" element={<Navigate replace to="/investor/biharhaitaiyar" />} />
                    </Routes>
                </>
            ) : null}
            {pathName === "department" ? (
                <>
                    <Layout section={"department"}>
                        {
                            generateRoute(allRoues)
                        }
                        {/* {allRoues.map((route, idx) => (
                            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                        ))} */}
                    </Layout>
                </>
            ) : null}
            {pathName === "investor" ? (
                <>
                    <Layout section={"investor"}>
                        {
                            generateRoute(allRoues)
                        }
                    </Layout>
                </>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        investor: state.Auth,
        department: state.DepartmentReducer.Login
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PagesRoute));